import React, { useState } from 'react';
import { MainContainer, PrimaryButton, PrimaryInput, PrimaryToolBar, Selectable } from '../../components';
import './Signup.css'
import { signupAPI } from '../../API'
import { useNavigate } from 'react-router-dom';

const SignupPage = () => {
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        age: 0,
        passions: [],
        contentPreference: 'Neutre',
        examplePreference: 'Neutre',
        acceptedPrivacy: false,
        langue: "Français",
        langueTr: "Anglais",
    });

    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            if (name === 'acceptedPrivacy') {
                setFormData({ ...formData, [name]: checked });
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const textOptions = ['Textes détaillés', 'Neutre', 'Résumés concis'];
    const exampleOptions = ['Exemples concrets', 'Neutre', 'Exemples abstraits'];
    const hobbies = [
        'Lecture', 'Sports', 'Musique', 'Peinture', 'Jeux vidéo',
        'Programmation', 'Voyage', 'Photographie', 'Yoga', 'Cuisine'
    ];

    const handleHobbyClick = (value, type) => {
        if (type === 'hobbies') {

            const isSelected = formData.passions.includes(value);

            // Toggle hobby selection, ensuring the user can select up to 5 hobbies
            if (isSelected) {
                const newList = formData.passions.filter(h => h !== value)
                setFormData({ ...formData, passions: newList });
            } else if (formData.passions.length < 5) {
                const newList = [...formData.passions, value]
                setFormData({ ...formData, passions: newList });
            }
        }
        else if (type === "text-preference") {
            if (formData.contentPreference === value) {
                setFormData({ ...formData, contentPreference: '' })
            } else
                setFormData({ ...formData, contentPreference: value })
        }
        else {
            if (formData.examplePreference === value) {
                setFormData({ ...formData, examplePreference: '' })

            } else
                setFormData({ ...formData, examplePreference: value })
        }
    };

    const [errorMessage, setErrorMessage] = useState('')

    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        if (!formData.email || !formData.password || formData.age <= 0) {
            setErrorMessage('Veuillez remplir votre email, mot de passe, et âge.');
            return;
        }

        if (!formData.examplePreference) {
            setFormData({ ...formData, examplePreference: 'Neutre' })
        }
        if (!formData.contentPreference) {
            setFormData({ ...formData, contentPreference: 'Neutre' })
        }
        // Check if privacy conditions are accepted
        if (!formData.acceptedPrivacy) {
            setErrorMessage('Veuillez accepter les conditions de confidentialité.');
            return;
        }

        setErrorMessage("")
        console.log('Form Data Submitted: ', formData);
        let result = "";
        try {
            result = await signupAPI(formData)
        } catch (e) {
            console.log("error logging in")
            setLoading(false)

        }
        setLoading(false)
        if (result.status === 201) {
            navigate("/login")
            alert("A verification email has been sent to your adress, please check your email")
        }
        // Handle form submission (e.g., send to API)
    };
    return (
        <form onSubmit={handleSubmit} style={{ flexDirection: 'row', display: "flex" }}>
            <PrimaryToolBar activeButton={4} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "space-around", flex: 1, overflow: 'auto', height: '100vh' }} >
                <div style={{ width: '30px', backgroundColor: 'red', height: "30px" }} />
                <MainContainer margin='20px' style={{ justifyContent: 'center', flexDirection: 'column', display: "flex" }} >
                    <div style={{ fontWeight: 'bold', fontSize: '30px', paddingBottom: '20px' }} >Signup</div>
                    <div style={{ flexDirection: "column", gap: "10px", display: "flex" }}>
                        <div className='signup-text' >Email:</div >
                        <PrimaryInput
                            width='35vw'
                            style={inputStyle}
                            name='email'
                            type="email"
                            hint="jean.dupont@mail.com"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    {/* <input type="email" name="email" value={formData.email} onChange={handleChange} required /> */}
                    <div style={{ flexDirection: "column", gap: "10px", display: "flex" }}>


                        <div className='signup-text' >Mot de passe:</div >
                        <PrimaryInput
                            width='35vw'
                            style={inputStyle}
                            name="password"
                            type="password"
                            hint='Password'
                            onChange={handleChange}
                            value={formData.password}
                        />
                    </div>

                    <div style={{ flexDirection: "column", gap: "10px", display: "flex" }}>


                        <div className='signup-text'>Age :</div >
                        <PrimaryInput
                            width='35vw'
                            style={inputStyle}
                            name='age' hint={0}
                            type="number"
                            value={formData.age}
                            onChange={handleChange}
                            required
                            min="1" />
                    </div>

                    <Spacer />

                    <div className='signup-text'>Sélectionnez vos 5 passions ou loisirs préférés</div  >
                    <div style={{ width: "40vw" }}>
                        <div className="hobbies-container">
                            {hobbies.map((hobby, index) => (
                                <Selectable name={hobby} index={index} onClick={() => handleHobbyClick(hobby, "hobbies")} isSelected={formData.passions.includes(hobby)} />
                            ))}
                        </div>

                    </div>

                    <Spacer />

                    <div className='signup-text'>Préférez-vous les textes détaillés ou les résumés concis ?</div>
                    <div className="hobbies-container">
                        {textOptions.map((option, index) => (
                            <Selectable
                                key={index}
                                index={index}
                                name={option}
                                isSelected={formData.contentPreference === option}
                                onClick={() => handleHobbyClick(option, "text-preference")} // Set the selected option
                            />
                        ))}
                    </div>

                    <Spacer />


                    <div className='signup-text'>Préférence entre des exemples concrets ou abstraits ?</div>
                    <div className="hobbies-container">
                        {exampleOptions.map((option, index) => (
                            <Selectable
                                key={index}
                                index={index}
                                name={option}
                                isSelected={formData.examplePreference === option}
                                onClick={() => handleHobbyClick(option, "example-preference")} // Set the selected option
                            />
                        ))}
                    </div>

                </MainContainer>
                <div style={{ height: '30px' }} />
                <div className='signup-text'>Avant de continuer, nous vous invitons à lire et accepter nos conditions de confidentialité.</div>
                <div style={{ flexDirection: 'row', display: 'flex', paddingBottom: "30px", }} >
                    <input style={{ marginRight: '10px', accentColor: '#FF4D4D' }} type="checkbox" name="acceptedPrivacy" checked={formData.acceptedPrivacy} onChange={handleChange} required />
                    <div className='signup-text'>J'accepte les conditions de confidentialité</div>
                </div>
                {errorMessage && <div style={{ color: 'red', fontSize: '14px', marginBottom: '10px' }}>{errorMessage}</div>}

                <PrimaryButton loading={loading} width='20vw' textColor='white' text={"S'inscrire"} type="submit" onClick={handleSubmit} background="linear-gradient(to right, #AB0B44, #FF4D4D)" />
                <div style={{ paddingBottom: '30px' }} />

            </div>
        </form>
    );
};

export { SignupPage };

const Spacer = () => {
    return (
        <div style={{ display: 'flex', width: "100%", justifyContent: 'center', flex: 1, paddingTop: "20px", paddingBottom: "20px", }}>

            <div style={{ width: '30vw', height: "2px", backgroundColor: '#D6D6D6' }} />
        </div>
    )
}


const inputStyle = {
    hintColor: '#636363',
    backgroundColor: '#f0f0f0',
}
import React, { useEffect, useState } from 'react';

// Mapping des langues pour la synthèse vocale
const languageMap = {
  english: 'en-US',
  french: 'fr-FR',
  german: 'de-DE',
  spanish: 'es-ES',
  italian: 'it-IT',
};

const TextToSpeech = ({ text, language }) => {
  const [isSpeaking, setIsSpeaking] = useState(false);

  useEffect(() => {
    if (text) {
      const utterance = new SpeechSynthesisUtterance(text);

      // Utiliser la langue spécifiée ou une langue par défaut
      utterance.lang = languageMap[language] || 'en-US';

      // Écouteurs pour gérer l'état de la lecture
      utterance.onstart = () => setIsSpeaking(true);
      utterance.onend = () => setIsSpeaking(false);

      // Annuler toute lecture en cours avant de commencer une nouvelle
      window.speechSynthesis.cancel();
      // Lire le texte
      window.speechSynthesis.speak(utterance);

      // Fonction de nettoyage à retourner dans useEffect
      return () => {
        window.speechSynthesis.cancel();
      };
    }
  }, [text, language]); 

  return (
    <div className="text-to-speech-container">
      {isSpeaking && <div className="animation">🎤</div>}
    </div>
  );
};

export default TextToSpeech;

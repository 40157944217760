import React from "react";
import "./PrimaryToolBar.css"
import { ToolBarElement } from "./ToolBarElement";
import { useNavigate } from "react-router-dom";
import { EquipeSvg, CompteSvg, GuideSvg, CartableSvg, DocumentSvg } from '../../../assets/svg';
import { useSelector } from "react-redux";

const PrimaryToolBar = ({ activeButton }) => {
    const userState = useSelector((state) => state.user)
    const navigate = useNavigate();
    return <div className="toolbar-main-container" style={{ backgroundColor: "red" }}   >
        <img src="../icons/png/logo.png" alt="Logo" style={logoStyle} onClick={() => navigate("/")} />
        <Separator />
        <ToolBarElement
            onClick={() => {
                if (userState.status === "loggedIn") {
                    navigate('/documents')
                } else {
                    navigate("/login")
                }
            }}
            text="DOCUMENT"
            Logo={DocumentSvg}
            isActive={activeButton === 0}
        />
        <ToolBarElement text="CARTABLE" Logo={CartableSvg} isActive={activeButton === 1} />
        <ToolBarElement text="GUIDE" Logo={GuideSvg} isActive={activeButton === 2} />
        <ToolBarElement text="EQUIPES" Logo={EquipeSvg} isActive={activeButton === 3} />
        <div style={{ flex: 1 }} />
        <ToolBarElement style={finalElementStyle} text="COMPTE" Logo={CompteSvg} isActive={activeButton === 4} onClick={() => {
            if (userState.uid) {
                navigate("/profile")
            } else {
                navigate("/login")
            }
        }} />
    </div>
}

export { PrimaryToolBar }

const logoStyle = {
    height: '70px',
    cursor: "pointer",
    padding: "20px",
    marginLeft: "20px",
    marginLeft: "20px"
};

const finalElementStyle = {
    marginBottom: "20px",
}

const Separator = () => {
    return (
        <div className="separator-container" >
            <div className="toolbar-separator">
            </div>
        </div>
    )
}


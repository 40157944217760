import { Routes, Route } from 'react-router-dom';
import { Home, SignupPage, Quiz, Login, Documents, ProfilePage, ChoixQuiz, ReExercice } from "./pages";
import { useDispatch } from "react-redux";
import { loginUser } from './reducers/src/userSlice';


const App = () => {
  const dispatch = useDispatch()
  const user = localStorage.getItem("user")
  if (user) {
    dispatch(loginUser(JSON.parse(user)))
    console.log(user)
  }
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/exercices-similaires" element={<ReExercice />} />
        <Route path="/teste-connaissance" element={<Quiz />} />
        <Route path="/choixQuiz" element={<ChoixQuiz />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/profile" element={<ProfilePage />} />
      </Routes>
    </>)

}

export default App
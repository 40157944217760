import { combineReducers } from 'redux';
import userReducer from './src/userSlice'; // Import your slice or reducer
import documentSlice from './src/documentSlice';

const rootReducer = combineReducers({
    user: userReducer,  // Combine multiple reducers
    document: documentSlice,
});

export default rootReducer;

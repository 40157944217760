import './Documents.css';
import { AppHeader, CercleDocument, FileImport, TextEditor } from '../../components';

function Documents() {
  /*const [text, setText] = useState('');
  const handelText=(text1)=>{
    setText(text1);
  }*/


  return (

    <div className="documents-main-container">
      <AppHeader showSearch={false} />
      <FileImport />
      <div style={sonDiv} >
        <CercleDocument />
      </div>
    </div>
  );
}

export { Documents };

const sonDiv = {
  position: "absolute",
  backgroundColor: "transparent",
  bottom: 0,
  left: 0,
  padding: "10px",
  height: "55px"
}
import React, { useState } from 'react';
import './tools.css';
import document from '../../../assets/img/iconToolbar/documentGen.svg';
import mess from '../../../assets/img/iconToolbar/iconMessage.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { registerDocument } from '../../../reducers/src/documentSlice';
import { InteractWithAPI } from '../ReponseIA/InteractWithAPI';

export function CercleDocument(text) {
  const documentState = useSelector((state) => state.document)
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const [isExpandedDoc, setIsExpandedDoc] = useState(false);
  const [isExpandedQuiz, setIsExpandedQuiz] = useState(false);
  const handleMouseEnterDoc = () => {
    setIsExpandedDoc(true);
  };

  const handleMouseLeaveDoc = () => {
    setIsExpandedDoc(false);
  };

  const handleMouseEnterQuiz = () => {
    setIsExpandedQuiz(true);
  };

  const handleMouseLeaveQuiz = () => {
    setIsExpandedQuiz(false);
  };

  const navigate = useNavigate();
  const quizNavigate = () => {
    navigate('/ChoixQuiz');

  };
  if (documentState.status === 'idle') {
    return null; // Or any fallback UI while documentText is not available
  }

  const handleFiche = async () => {
    try {
      console.log("la valeur de documentState.value récupere est dans handleFiche", documentState.value);

      const data = await InteractWithAPI('fiche', documentState.value, user.langue, user.age, user.passions, user.contentPreference, user.examplePreference);
      dispatch(registerDocument({ document: data, type: "docx", pdfFile: null }));
      console.log("la valeur de text récupere est dans handleFiche", data);
    } catch (error) {
      console.error('Erreur lors de l\'évaluation de la réponse:', error);
    }
  };

  return (
    <div className='cercle-container'>
      <div
        className={`cercle ${isExpandedDoc ? 'expanded' : ''}`}
        onMouseEnter={handleMouseEnterDoc}
        onMouseLeave={handleMouseLeaveDoc}
        onClick={() => handleFiche()}
      >
        <img className='icon' src={document} alt="iconDoc" />
        {isExpandedDoc && <span className="phrase">Générer une fiche</span>}
      </div>
      <div
        className={`cercle ${isExpandedQuiz ? 'expanded' : ''}`}
        onMouseEnter={handleMouseEnterQuiz}
        onMouseLeave={handleMouseLeaveQuiz}
        onClick={quizNavigate}
      >
        <img className='icon' src={mess} alt="iconQuiz" />
        {isExpandedQuiz && <span className="phrase">Générer un quiz</span>}
      </div>
    </div>
  );
}

export default CercleDocument;

import React from 'react';
import './reponseIA.css';
import back from "../../../assets/img/ReponseIA/back.svg";
import triangle from "../../../assets/img/ReponseIA/triangle.svg";
import logo from "../../../assets/img/ReponseIA/logo.png";
import TextToSpeech from './TextToSpeech';

function ReponseIAText({ phraseSelec, action, showResponse, response, loading, onBackClick }) {
  return (
    !showResponse ? (
      <div className="ai-main-container"></div>
    ) : (
      <div className="ai-main-container">
        <div className="phrase-container">
          <div className="text-container">
            <p className="text-phrase">{phraseSelec}</p>
          </div>
          <img className="back-icon" alt="back" src={back} onClick={() => onBackClick()} />
        </div>
        <div className="response-container">
          {action === 'lire' ? (
            <TextToSpeech text={phraseSelec} language={'french'} />
          ) : (
            <div className="text-response">
              {loading ? 'Loading...' : response}
            </div>
          )}
        </div>
        <img className="triangle" alt="Vector" src={triangle} />
        <img className="logo" alt="logo" src={logo} />
      </div>
    )
  );

}

export { ReponseIAText };

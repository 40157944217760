import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        email: null,
        uid: null,
        examplePreference: null,
        contentPreference: null,
        age: null,
        passions: [],
        status: 'loggedOut',
        langue: 'Français',
        langueTr: 'Anglais',

    },
    reducers: {
        loginUser(state, action) {
            state.status = "loggedIn"
            if (action.payload.email) {
                state.email = action.payload.email;
            }
            if (action.payload.uid) {
                state.uid = action.payload.uid;
            }

            if (action.payload.examplePreference) {
                state.examplePreference = action.payload.examplePreference;
            }

            if (action.payload.contentPreference) {
                state.contentPreference = action.payload.contentPreference;
            }

            if (action.payload.age) {
                state.age = action.payload.age;
            }

            if (action.payload.passions) {
                state.passions = action.payload.passions;
            }
            if (action.payload.langue) {
                state.langue = action.payload.langue;
            }
            if (action.payload.langueTr) {
                state.langueTr = action.payload.langueTr;
            }

        },
        logoutUser(state) {
            state.status = "loggedOut"
            state.email = null;
            state.uid = null;
            state.examplePreference = null;
            state.contentPreference = null;
            state.age = null;
            state.passions = null;
        },
    },
});

export const { loginUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;

import './tools.css';
import corriger from "../../../assets/img/IconTools/corriger.svg";
import definition from "../../../assets/img/IconTools/definition.svg";
import dictionnaire from "../../../assets/img/IconTools/dictionnaire.svg";
import explication from "../../../assets/img/IconTools/explication.svg";
import lecture from "../../../assets/img/IconTools/lecture.svg";
import resume from "../../../assets/img/IconTools/resume.svg";
import traduire from "../../../assets/img/IconTools/traduire.svg";

export const Tool = ({ handleActionClick, style }) => {
    return (
        <div className="tools" style={style}>
            <img className="action" title="corriger" alt="corriger" src={corriger} onClick={() => handleActionClick('correct')} />
            <img className="action" title="définir" alt="definition" src={definition} onClick={() => handleActionClick('define')} />
            <img className="action" title="dictionnaire" alt="dictionnaire" src={dictionnaire} onClick={() => handleActionClick('dictionnaire')} />
            <img className="action" title="expliquer" alt="explication" src={explication} onClick={() => handleActionClick('explain')} />
            <img className="action" title="lire" alt="lecture" src={lecture} onClick={() => handleActionClick('lire')} />
            <img className="action" title="résumer" alt="resume" src={resume} onClick={() => handleActionClick('resume')} />
            <img className="action" title="traduire" alt="traduire" src={traduire} onClick={() => handleActionClick('translate')} />
        </div>
    );
};

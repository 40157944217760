import React, { useState, useEffect, useRef, act, Children } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './QuillEditor.css'; // import styles
import * as quillToWord from 'quill-to-word';
import { saveAs } from 'file-saver';
import { Donwload } from '../../../assets/svg/src/download';
import { ReponseIAText } from '../ReponseIA/reponseIA';
import { useSelector, useDispatch } from 'react-redux';
import { registerDocument } from '../../../reducers/src/documentSlice';
import { Tool } from '../DocumentTools/Tools';
import { InteractWithAPI } from '../ReponseIA/InteractWithAPI';
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import "./QuillEditor.css"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const TextEditor = (props) => {
    const documentState = useSelector((state) => state.document)
    const dispatch = useDispatch()

    const user = useSelector((state) => state.user);

    const toolRef = useRef(null);

    const [loading, setLoading] = useState(false)
    const [showAIResponse, setShowAIResponse] = useState(false)
    const [toolPosition, setToolPosition] = useState({ top: 0, left: 0 });
    const [selectedText, setSelectedText] = useState('');
    const [oldselectedText, setOldSelectedText] = useState('');
    const [reponse, setReponse] = useState('');
    const quillRef = useRef(null); // Reference to the ReactQuill instance

    const languageMap = {
        english: 'en-US',
        french: 'fr-FR',
        german: 'de-DE',
        spanish: 'es-ES',
        italian: 'it-IT',
    };

    const handleDocumentChange = () => {
        if (documentState.status !== "idle" && quillRef.current) {
            const editor = quillRef.current.getEditor();
            console.log(editor)
            const html = editor.root.innerHTML;
            dispatch(registerDocument({ document: html, type: documentState.type }))
        }
    }

    const handleDownload = async () => {
        console.log("donwload")

        const editor = quillRef.current.getEditor(); // Access Quill instance
        const delta = editor.getContents(); //
        const config = {
            exportAs: 'blob'
        }
        const doc = await quillToWord.generateWord(delta, config)
        saveAs(doc, "Eugenia document.docx")
    };

    const handleSelectionChange = (text) => {
        const editor = quillRef.current.getEditor(); // Access Quill instance
        const range = editor.getSelection(); // Get the selection range

        if (range && range.length > 0) {
            const selectedText = editor.getText(range.index, range.length);
            setSelectedText(selectedText);
            setOldSelectedText(selectedText);
        } else {
            setSelectedText('');
        }
    };

    const readSelection = (language) => {
        const utterance = new SpeechSynthesisUtterance(selectedText);

        // Utiliser la langue spécifiée ou une langue par défaut
        utterance.lang = languageMap[language] || 'en-US';

        // Écouteurs pour gérer l'état de la lecture

        // Annuler toute lecture en cours avant de commencer une nouvelle
        window.speechSynthesis.cancel();
        // Lire le texte
        window.speechSynthesis.speak(utterance);

        // Fonction de nettoyage à retourner dans useEffect
        return () => {
            window.speechSynthesis.cancel();
        };
    }

    const handleSetAction = async (action, langue, langueTr) => {

        setShowAIResponse(true)
        setLoading(true)
        try {
            if (action === 'translate') {
                const result = await InteractWithAPI(action, selectedText, user.langue, user.langueTr, user.age, user.passions, user.contentPreference, user.examplePreference);
                setReponse(result);
            } else if (action === "lire") {
                const result = await InteractWithAPI(action, selectedText, user.langue, user.langueTr, user.age, user.passions, user.contentPreference, user.examplePreference);
                setReponse("🎤")
                readSelection(result)
            }
            else {
                console.log("les prefrences de l'utilisateur", user.passions);
                const result = await InteractWithAPI(action, selectedText, user.langue, user.age, user.passions, user.contentPreference, user.examplePreference);
                setReponse(result);

            }
            console.log("api api" + reponse)
        } catch (error) {
            setReponse('Error fetching the response');
            console.error('Error in ReponseIAText:', error);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (documentState.type === "pdf") {
            return
        }
        if (selectedText) {
            console.log(selectedText)
            const domSelection = window.getSelection();
            if (domSelection.rangeCount > 0) {
                const range = domSelection.getRangeAt(0).getBoundingClientRect();
                const editorBounds = toolRef.current.getBoundingClientRect();
                setToolPosition({
                    top: range.bottom + window.scrollY + 5, // Position it slightly below the selection
                    left: range.left + window.scrollX - editorBounds.width / 2 + range.width / 2,
                });
            }
        }
    }, [selectedText]);



    return (
        <div style={{ flexDirection: 'column', overflow: "hidden", height: "100", display: "flex", flex: 1 }}>
            <EditorToolBar showBar={documentState.type !== "pdf"} onDownload={handleDownload} />
            <div className='editor-main-container'>

                {documentState.type === "pdf" ?
                    <PdfEditor
                        numPages={documentState.pdfFile.numPages}
                        pdfFile={documentState.pdfFile.file}
                        pageNumber={documentState.pdfFile.pageNumber}
                        onPdfLoadSuccess={props.onPdfLoadSuccess}
                        handleSetAction={handleSetAction}
                        toolRef={toolRef}
                        setSelectedText={(text) => {
                            setOldSelectedText("" + text)
                            setSelectedText("" + text)
                        }}
                    />
                    : <div >
                        <ReactQuill
                            className='editor-container'
                            ref={quillRef}
                            value={documentState.value}
                            onChangeSelection={handleSelectionChange}
                            onChange={handleDocumentChange}
                            modules={{ toolbar: "#toolbar" }} // Disable built-in toolbar
                        />
                        {selectedText && selectedText !== '' && (
                            <div ref={toolRef} style={{ top: toolPosition.top, left: toolPosition.left, position: 'absolute' }}>
                                <Tool style={{ left: "198px" }} handleActionClick={handleSetAction} />
                            </div>
                        )}
                    </ div>}
                <div style={responseWrapperStyle}>
                    <ReponseIAText
                        showResponse={showAIResponse}
                        response={reponse}
                        phraseSelec={oldselectedText}
                        loading={loading}
                        onBackClick={() => setShowAIResponse(false)}
                    />
                </div>
            </div>
        </div>
    );
};

const PdfEditor = (props) => {
    const [selection, setSelection] = useState(null); // Store selected text
    const [toolPosition, setToolPosition] = useState({ x: 0, y: 0 }); // Tool position
    const [showTool, setShowTool] = useState(false); // Control visibility of Tool component

    // Function to handle text selection
    const handleSelection = () => {
        const selectedText = window.getSelection();
        if (selectedText.toString().length > 0) {
            // Store the selected text in the 'selection' state
            setSelection(selectedText.toString());

            // Get the position of the selected text
            const range = selectedText.getRangeAt(0).getBoundingClientRect();
            const scrollY = window.scrollY || document.documentElement.scrollTop;
            const scrollX = window.scrollX || document.documentElement.scrollLeft;

            // Set tool position relative to the selected text
            setToolPosition({
                x: range.left + scrollX,
                y: range.top + scrollY,
            });

            // Show the Tool component
            console.log("selectionn " + selectedText)
            props.setSelectedText(selectedText)
            setShowTool(true);
        } else {
            setShowTool(false);
        }
    };
    useEffect(() => {
        // Add event listener for text selection in the PDF document
        document.addEventListener('mouseup', handleSelection);

        // Clean up event listener
        return () => {
            document.removeEventListener('mouseup', handleSelection);
        };
    }, []);

    return (
        <div style={responseWrapperStyle}>
            <Document file={props.pdfFile} onLoadSuccess={props.onPdfLoadSuccess}>
                {Array.from({ length: props.numPages }, (_, i) => i + 1).map((page) => (
                    <Page
                        key={page}
                        pageNumber={page}
                        renderTextLayer={true}
                        renderAnnotationLayer={false}
                    />
                ))}
                {props.children}
            </Document>

            {showTool && (
                <div
                    style={{
                        position: 'absolute',
                        top: `${toolPosition.y}px`,
                        left: `${toolPosition.x}px`,
                        zIndex: 10,
                    }}
                >
                    <Tool style={{ left: "10px", top: "20px" }} handleActionClick={props.handleSetAction} />
                </div>
            )}
        </div>
    );
};



const EditorToolBar = ({ showBar, onDownload }) => {
    if (!showBar) {
        return null
    } else {

        return (
            <div style={{ flexDirection: 'row', display: "flex", justifyContent: 'center' }} id="toolbar">
                <span class="ql-formats">
                    <select class="ql-font">

                    </select>
                    <select class='ql-size' >
                    </select>
                </span>
                <Divider />
                <span class="ql-formats">
                    <select class="ql-color">

                    </select>
                    <button class="ql-indent" value="+1"></button>
                </span>
                <Divider />
                <span class="ql-formats">
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                    <button class="ql-strike"></button>
                </span>

                <Divider />
                <span class="ql-formats">
                    <select class="ql-align"></select>
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                </span>

                <Divider />
                <span class="ql-formats">

                    <button style={{ display: "flex", justifyContent: "center", }} id="custom-button" onClick={onDownload}>
                        <Donwload fillColor="#444444" />
                    </button>
                </span>
            </div>
        );

    }
};

const responseWrapperStyle = {
    width: "50vw",
    color: "#444444",
    display: "flex",
    overflow: "auto",
    flex: 1
}

const Divider = () => {
    const dividerStyle = {
        height: '20px',
        width: '1px',
        backgroundColor: '#ccc',
        marginRight: '10px',


    };
    return <div style={dividerStyle} />
}


export { TextEditor };

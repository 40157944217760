import axios from "axios";
import { API_URL } from "../globals";

const update = async (uid, age, passions, contentPreference, examplePreferenceuserData, langue, langueTr) => {
    try {

        const userData = {
            uid: uid,
            age: age,
            passions: passions,
            contentPreference: contentPreference,
            examplePreference: examplePreferenceuserData,
            langue: langue,
            langueTr: langueTr
        }
        console.log(userData)
        // Successful login
        const response = await axios.post(API_URL + "users/update", userData);

        return response; // You can return the user details or any other data you want
    } catch (error) {
        // Handle errors here
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Login error: ", errorCode, errorMessage);
        throw error; // Re-throw error to handle it in the caller
    }
};


export { update }
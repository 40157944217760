import React, { useState } from 'react';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton';
import { PrimaryInput } from '../PrimaryInput/PrimaryInput'
import './AppHeader.css'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserSvg } from '../../../assets/svg';
import { logoutUser } from '../../../reducers/src/userSlice';


const AppHeader = ({ showSearch = true }) => {
    const userState = useSelector((state) => state.user)
    const navigate = useNavigate()
    return (
        <header className='primary-header'>
            <img src="../icons/png/logo.png" alt="Logo" style={logoStyle} />
            <PrimaryButton
                to="/"
                text="Home"
                width="10vw"
                background="white"
                icon="../icons/svg/home.svg"
                textColor="#e73a4a"
                borderRadius="26px"
                onClick={() => navigate('/')}
            />
            {showSearch ?
                <PrimaryInput icon={"../icons/svg/search.svg"} hint='Rechercher...' width='40vw' style={{ hintColor: "white" }} />
                : <div style={{ width: '40vw' }} ></div>
            }

            {userState.status === 'loggedOut' ? <PrimaryButton
                to="/login"
                text="Se connecter"
                width="15vw"
                background="white"
                icon="../icons/png/person.png"
                textColor="#e73a4a"
                borderRadius="26px"
                onClick={() => navigate('/login')}
            />
                :
                <UserDropdown email={userState.email} />
            }
        </header>
    );
};

const UserDropdown = ({ email }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleLogout = (type) => {
        if (type) {
            navigate("/profile")
        } else {
            dispatch(logoutUser())
            localStorage.setItem("user", "")
        }
    };

    return (

        <div style={{ position: 'relative', display: 'inline-block' }}>
            <button
                onClick={() => setDropdownVisible(!dropdownVisible)}
                style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer', alignItems: "center", flexDirection: "column", display: "flex" }}
            >
                <UserSvg fillColor="white" />
                <div style={{ color: "white", marginTop: "5px" }}>
                    {email}
                </div>
            </button>

            {dropdownVisible && (
                <div style={dropdownStyle}>
                    <button style={dropdownItemStyle} onClick={() => handleLogout("profile")}>Profile</button>
                    <button style={dropdownItemStyle} onClick={() => handleLogout()}>Logout</button>
                </div>
            )}
        </div>
    );
};

// Dropdown CSS styles
const dropdownStyle = {
    position: 'absolute',
    top: '40px',  // Adjust based on your layout
    right: '0',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    zIndex: 1000,
};

const dropdownItemStyle = {
    padding: '10px 20px',
    width: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    fontSize: '14px',
};

export default UserDropdown;

const logoStyle = {
    height: '10vh',
    cursor: "pointer"
};




export { AppHeader };
